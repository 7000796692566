.selectedBaseLayer {
	border: 4px solid rgba(249, 252, 255, 0.4);
	border-radius: 5px;
	width: 56px;
	height: 54px;
	margin-top: 0px;
}

.baseLayer {
	width: 56px; 
	height: 54px;
	border: 4px solid rgba(249, 252, 255, 0.4);
	background: linear-gradient(0deg, rgba(249, 252, 255, 0.09), rgba(249, 252, 255, 0.09)), #292A2B;
}

.topBaseLayer {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}

.bottomBaseLayer {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.selectedBaseLayerInList {
	border: 4px solid #876D25;
}

.baseLayer:hover {
	border: 4px solid #584F35;
}

.baseLayers {
	margin-right:4px;
	margin-top: -24px;
}