.managementAreaSearchContainer {
    width: 100%;
    height: 100%;
    font-family: Roboto;
}
.managementAreaSearchContent {
    margin: 8px 8px 8px 8px;
}

.text {
    margin-top: 4px;
    text-align:left;
    padding-left: 8px;
    color: rgba(249, 252, 255, 0.75);
}

.filterContainer {
    position: absolute;
    z-index: 100;
    min-width: max-content;
}

.chip {
    height: 50px;
    padding-top: 8px;
    flex-wrap: wrap;
    grid-row-gap: 1px;
    grid-column-gap: 5px;
}
