.label {
  color: #f9fcff;
}

.root {
  min-width: 220px;
}

.input {
  margin-top: -18px;
}

.indicator {
  color: #F9FCFF;
}

.listbox {
  width: 220px;
  height: auto;
}

.listOption:hover {
  color: #93BAF5;
  background-color: rgba(147, 186, 245, 0.08);
}