.container {
    font-family: Roboto;
    font-size: 14px;
    width: 350px;
    background-color: #424345;
    color: #D6D7D8;
    border-radius: 10px;
}

.content {
    padding: 10px 10px 10px 6px;
}

.objectsContainer {
    width: 100%;
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
}

.objectContainer {
    border: 4px solid #424345;
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
    overflow: hidden;
    padding: 5px;
    box-shadow: 0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2);
}

.objectContainer:hover {
    border: 4px solid #584F35;
}

.bigProp {
    font-size: 16px;
    color: #EAEBEB;
    padding-top: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    min-width: 150px;
}

.sessionLabel {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}