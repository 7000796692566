.listContainer {
    border: 3px solid rgba(249, 252, 255, 0.4);
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.09), rgba(249, 252, 255, 0.09)), #292A2B;
    margin-left: 10px;
    border-bottom: none;
}

.listItem {
    padding-top: 6px;
    width: 25px;
}

    .listItem:last-child {
        padding-top: 6px;
        padding-bottom: 6px;
    }

.iconBtn {
    width: 10px;
    height: 10px;
}

.listItem button {
    padding: 2px !important;
}
