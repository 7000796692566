.itemHeader {
    font-size: 12px;
    color: #747474;
    margin-bottom: 7px;
}

.itemContent {
    font-size: 24px;
    color: white;
}

