.content {
    text-align: left;
    height: 100%;
    font-family: Roboto;
}

.loader {
    margin-bottom: 20px;
    margin-right: 20px;
}

.footerContainer {
    background-color: #17191A;
    color: #FFFFFF;
}

.footerContent {
    margin-left: 20px;
    margin-top: 6px;
}

.footerText {
    margin-top: 4px;
    color: rgba(249, 252, 255, 0.75);
}

.noResultsText {
    color: rgba(249, 252, 255, 0.4);
}