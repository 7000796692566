
.map {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	background-color:#2b2b2b;

	/* background-color: #292A2B; */
}
