.menuIcons {
    position: absolute;
    right: 0px;
    top: 0px;
}

.mapBaseLayerSelectorContainer {
    z-index: 1200;
    position: absolute;
    left: 140px;
    bottom: 69px;
}

.mapToolbarContainer {
    position: absolute;
    left: 105px;
    bottom: 65px;
    z-index: 1200;
    width: 20px;
}

.mapNetworkLayersContainer {
    font-family: Roboto!important;
    font-style: normal;
    font-weight: 500;
    position: absolute;
    left: 145px;
    bottom: 100px;
    z-index: 1200;
    width: 500px;
    max-height: 600px;
    overflow: hidden auto;
}

.noResultsMessageText {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(249, 252, 255, 0.75);
}

.tabsContainer {
    background-color: #2F3135;
    /* color: #95C5E7; */
    font-family: Roboto;
    position: absolute;
    right: 5px;
    top: 70px;
    opacity: 0.92;
    bottom: 5px;
}

.searchContainer {
    width: 100%;
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
    /* elevation/very low */

    box-shadow: 0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2);
}

.managementAreaSearchResultsContainer {
    width: 100%;
    background: #292A2B;
    height: 100%;
    overflow: hidden;
}

.cycleSelector {
    position: absolute;
    width: 220px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.text {
    color: rgba(249, 252, 255, 0.75);
}
