.layerPanelStyle {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}
.listContainer {
    background-color: #464A4F;
    font-family: Roboto;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.03), rgba(249, 252, 255, 0.03)), #292A2B;
    box-shadow: 0px 1px 1px rgba(30, 30, 32, 0.14), 0px 2px 1px rgba(30, 30, 32, 0.12), 0px 1px 3px rgba(30, 30, 32, 0.2);
}

.selected {
    background: linear-gradient(0deg, rgba(249, 252, 255, 0.08), rgba(249, 252, 255, 0.08)), linear-gradient(0deg, rgba(249, 252, 255, 0.11), rgba(249, 252, 255, 0.11)), #292A2B;
}

.layerTabsContainer {
    background-color: rgb(62 63 65);
    color: rgba(249, 252, 255, 0.75);
    font-family: Roboto;
    opacity: 0.92;
    text-align: left;
    padding: 15px;
}