.product {
  height: 52px;
}

.productName {
  font-weight: 500;
}

.productIcon {
  width: 25;
  height: 25;
}
