.label {
  color: #f9fcff;
}

.root {
  min-width: 220px;
  flex-wrap: 'nowrap';
}

.input {
  margin-top: -18px;
}

.indicator {
  color: #f9fcff;
}

.listbox {
  width: 100%;
  height: auto;
}

.listOption:hover {
  color: #93baf5;
  background-color: rgba(147, 186, 245, 0.08);
}
